<template>
  <div class="login-register">
    <heads></heads>
    <!-- 字母 -->
    <img
      :src="require('@/assets/images/common_image_letters.png')"
      class="letters"
    >
    <!-- 飞机 -->
    <img
      :src="require('@/assets/images/home_image_satellite.png')"
      class="plane-animate"
    >
    <!-- 流星 -->
    <img
      :src="require('@/assets/images/home_image_meteor.png')"
      class="meteor1-animate"
    >
    <img
      :src="require('@/assets/images/home_image_meteor.png')"
      class="meteor2-animate"
    >
    <!-- 地球 -->
    <img
      :src="require('@/assets/images/sign_in_image_moon.png')"
      class="planet-animate"
    >

    <!-- 
     -->
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header";
import "@/assets/scss/animate.css";
export default {
  props: {},
  components: {
    heads: Header,
  },
  data() {
    return {
      name: this.$route.name,
    };
  },
  created() {
    console.log(this.$route.name);
  },
  mounted() {
    this.$nextTick(() => {
      let container = document.getElementsByClassName("login-register")[0];
      container.style.height = window.innerHeight + "px";
    });
  },
};
</script>

<style lang="scss" scoped>
.login-register {
  position: relative;
  width: 100%;
  // height: 100%;
  background-image: url("../../assets/images/sign_in_image_bg.png");
  background-size: cover;
  .header-nav {
    background: transparent;
    &.header-nav::after {
      border: none;
    }
    .van-icon {
      color: #fff;
    }
  }
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .transpranent-input {
    width: 100%;
    height: 1.33rem;
    background: rgba($color: #1b188f, $alpha: 0.17);
    border-radius: 0.72rem;
    margin-top: 0.67rem;
    .van-field__body {
      height: 100%;
    }
    .van-field__control {
      color: #fff;
    }
    &::after {
      border: none;
    }
  }
  .login-register-btn {
    width: 100%;
    height: 1.33rem !important;
    background: #ffffff;
    box-shadow: 0rem 0.05rem 0.43rem 0rem rgba(0, 0, 0, 0.15);
    border-radius: 0.72rem;
    opacity: 0.95;
    height: 0.67rem;
    font-size: 0.48rem;
    color: #304fcd;
    margin-top: 0.8rem;
  }
  .bottom-jump {
    position: absolute;
    bottom: 12%;
    // bottom: 2.64rem;
    width: 100%;
    color: rgb(99, 97, 97);
    font-size: 0.37rem;
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -8%;
    .line {
      display: inline-block;
      width: 0.03rem;
      height: 0.43rem;
      background: #fff;
      margin-left: 0.51rem;
      margin-right: 0.51rem;
    }
  }
  .letters {
    position: absolute;
    left: 8%;
    top: 1.95rem;
    width: 6.61rem;
    height: 1.33rem;
  }
  // 飞机
  .plane-animate {
    position: absolute;
    top: 1.31rem;
    left: -2rem;
    z-index: 0;
    width: 6.55rem;
    opacity: 0.3;
    animation: plane 7s infinite ease;
  }
  // 流星
  .meteor1-animate {
    position: absolute;
    top: 0rem;
    right: 0rem;
    width: 1.77rem;
    height: 1.77rem;
    // opacity: .5;
    z-index: 1;
    animation: meteor1 5s infinite ease;
  }
  .meteor2-animate {
    position: absolute;
    top: 0rem;
    right: 0rem;
    width: 1.96rem;
    height: 1.96rem;
    // opacity: .5;
    z-index: 1;
    animation: meteor2 5s infinite ease;
  }
  .planet-animate {
    position: absolute;
    top: 5.5rem;
    left: 8.8%;
    z-index: 0;
    height: 9.39rem;
    animation: planet 10s infinite ease-in;
  }
}
</style>
